/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable no-script-url */
/* eslint-disable react/no-unescaped-entities */
import React, {Component} from 'react';

import {Button, Form, Row, Col} from 'react-bootstrap';
import {connect} from 'react-redux';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import queryString from 'query-string';

import FloatingAlert from '../Elements/FloatingAlert';
import SideBarMenu from '../SideBarMenu';

import LightBox from '../Elements/LightBox';
import SearchBox from '../Elements/SearchBox';
import {PreparingDownload} from '../Modals/PreparingDownload';
import ListCetificates from './Home/ListCertificates';

import {
  getFilterOptions,
  getHomeProducts,
  get_famillies,
  getProductWithId,
} from '../../Actions/HomePageActions';

import {homeGetImageStatus} from '../../Actions/AdminActions';

import api from '../../utils/api';
import {base_url, serverUrl} from '../../utils/env';

import {home_lable} from '../../utils/language_untils';
import {appendFormData, copy_url} from '../../utils/commonFunctions';

import defaultPlayImg from '../../Assets/images/default-video.png';
import {gen_uri} from '../../utils/commonFunctions';

class HomePage extends Component {
  state = {
    product_loading: false,
    families_loading: false,
    floatingAlert: {
      show: false,
      variant: '',
      message: '',
    },
    fiterOption: {},
    viewPrefer: {
      product: false,
      show_pdf: false,
      show_bilders: false,
      show_videos: false,
      show_certificates: false,
    },
    filterData: {product: '', category: '', familie: ''},
    categories: [],
    families: [],
    products: [],
    choosenProduct: {},
    lightBox: {
      show: false,
      images: [],
      videos: [],
      customIndex: 0,
    },
    visibleImages: [],
    preparingDownload: false,
    resetSearchForm: false,
    currentLang: '',
    copied: false,
  };

  UNSAFE_componentWillMount = () => {
    this.showMessages();
    this.props.getFilterOptions().then(res => {
      const params = queryString.parse(this.props.location.search);
      let filterData = {};

      filterData.category = params.category ? params.category : '';
      filterData.familie = params.familie ? params.familie : '';
      filterData.product = params.product ? params.product : '';

      this.setState(
        {
          filterData,
        },
        () => {
          if (filterData.category && filterData.familie) {
            this.get_famillies(filterData.category);
            this.get_products();
          }

          if (
            filterData.product &&
            filterData.category === '' &&
            filterData.familie === ''
          ) {
            this.props.getProductWithId(filterData.product).then(res => {
              if (res.type === 'success') {
                const choosenProduct = res.product;

                let filterData = {...this.state.filterData};

                filterData.category = choosenProduct.category_id_num;
                filterData.familie = choosenProduct.familie_id_num;

                this.get_famillies(filterData.category);

                this.setState(
                  {
                    filterData,
                    choosenProduct,
                  },
                  () => {
                    this.get_products();
                  },
                );
              }
            });
          }
        },
      );
    });
  };

  UNSAFE_componentDidMount = () => {
    this.showFloatingAlert();
  };

  showFloatingAlert = () => {
    const isFloating = document.getElementsByClassName('floating-alert');
    if (isFloating.length) {
      setTimeout(() => {
        isFloating[0].classList.add('show');
      }, 500);
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      categories: nextProps.filters.categories,
      families: nextProps.famillies,
      products: nextProps.products,
      currentLang: nextProps.currentLang,
    });
  }

  showMessages = () => {
    const query = new URLSearchParams(this.props.location.search);
    const variant = query.get('type');
    const message = query.get('message');
    if (message) {
      this.setState({
        floatingAlert: {
          show: true,
          variant,
          message,
        },
      });
    }
  };

  redirectMe = url => {
    const isFloating = document.getElementsByClassName('floating-alert');
    this.props.history.push(url);
    this.showMessages();
    setTimeout(() => {
      isFloating[0].classList.add('show');
    }, 500);
  };

  closeAlert = () => {
    this.setState({
      floatingAlert: {
        show: false,
        variant: '',
        message: '',
      },
    });
  };

  filterUpdate = e => {
    this.setState({
      filterData: {
        ...this.state.filterData,
        [e.target.name]: e.target.value,
      },
      viewPrefer: {
        ...this.state.viewPrefer,
        product: false,
      },
      copied: false,
    });

    if (e.target.name == 'category') {
      this.setState({resetSearchForm: true});
      this.get_famillies(e.target.value);
    } else if (e.target.name == 'familie') {
      this.get_products();
    }
  };

  get_famillies = category_id => {
    this.setState({
      families_loading: true,
      product_loading: false,
      fiterOption: {
        ...this.state.fiterOption,
        category: false,
        familie: true,
        products: false,
      },
      choosenProduct: {},
      visibleImages: [],
      lightBox: {
        show: false,
        images: [],
        videos: [],
        customIndex: 0,
      },
    });

    const cateData = {category_id};
    const cateData1 = appendFormData(cateData);
    this.props.get_famillies(cateData1).then(res => {
      this.setState({
        families_loading: false,
      });
    });
  };

  get_products = () => {
    this.setState(
      {
        families_loading: false,
        product_loading: true,
        fiterOption: {
          ...this.state.fiterOption,
          familie: true,
          products: true,
        },
        choosenProduct: {},
        viewPrefer: {
          product: false,
          show_pdf: false,
          show_bilders: false,
          show_videos: false,
        },
      },
      () => {
        const {category, familie, product} = this.state.filterData;

        const filterData = new FormData();
        filterData.append('category', category);
        filterData.append('familie', familie);

        this.props.getHomeProducts(filterData).then(res => {
          if (res.type === 'error') {
            this.setState(
              {
                product_loading: false,
                floatingAlert: {
                  show: true,
                  variant: 'danger',
                  message: res.message,
                },
              },
              () => {
                this.showFloatingAlert();
                this.resetFilter();
              },
            );
          } else {
            let viewPrefer = {...this.state.viewPrefer};

            if (product !== '') {
              const params = queryString.parse(this.props.location.search);
              const productsLength = this.state.products;
              for (let i = 0; i < productsLength.length; i++) {
                if (`${productsLength[i]['id']}` === product) {
                  this.setState({
                    filterData: {
                      ...this.state.filterData,
                      product: i,
                    },
                    choosenProduct: productsLength[i],
                  });
                }
              }

              viewPrefer.product = true;
              viewPrefer.show_pdf = params.pdf === 'show' ? true : false;
              viewPrefer.show_bilders = params.images === 'show' ? true : false;
              viewPrefer.show_videos = params.videos === 'show' ? true : false;
              viewPrefer.show_certificates =
                params.certificates === 'show' ? true : false;
            }

            this.setState(
              {
                product_loading: false,
                viewPrefer,
                fiterOption: {
                  ...this.state.fiterOption,
                },
              },
              () => {
                if (product) {
                  const {filterData} = this.state;
                  this.setChoosenProduct(filterData.product);
                }
              },
            );
          }
        });
      },
    );
  };

  viewPreferences = e => {
    this.setState({
      viewPrefer: {
        ...this.state.viewPrefer,
        [e.target.name]: e.target.checked,
      },
    });
  };

  setChoosenProduct = productId => {
    const {products} = this.state;

    if (products[productId]) {
      const choosenProduct = products[productId];

      this.props.homeGetImageStatus(choosenProduct.id).then(res => {
        this.setState(
          {
            visibleImages: res.image_status,
            choosenProduct: choosenProduct,
          },
          () => {
            this.savelightboxImages();
          },
        );
      });
    } else {
      this.setState({
        visibleImages: [],
        choosenProduct: {},
        lightBox: {
          show: false,
          images: [],
          videos: [],
          customIndex: 0,
        },
      });
    }
  };

  savelightboxImages = () => {
    const {choosenProduct, visibleImages} = this.state;

    // const proImages = [
    //     "iso_1", "iso_2", "front", "side", "top", "detail_1", "detail_2", "detail_3", "detail_4", "detail_5", "lifestyle_1", "lifestyle_2", "lifestyle_3", "lifestyle_4", "lifestyle_5", "lifestyle_6", "lifestyle_7", "lifestyle_8",
    // ];

    const lightboxImages = [];
    if (visibleImages && Object.entries(visibleImages).length > 0) {
      visibleImages.map((image, index) => {
        if (choosenProduct.product_fields[image.key]) {
          lightboxImages.push(choosenProduct.product_fields[image.key][0]);
        }
      });
    }

    const proVideos = [
      'video_1_de',
      'video_2_de',
      'video_3_de',
      'video_4_de',
      'video_5_de',
    ];

    const lightboxVideos = [];

    proVideos.map((field, index) => {
      if (choosenProduct.product_fields[field]) {
        lightboxVideos.push(choosenProduct.product_fields[field][0]);
      }
    });

    this.setState({
      lightBox: {
        ...this.state.lightBox,
        images: lightboxImages,
        videos: lightboxVideos,
      },
    });
  };

  rederVideoGrid = () => {
    const {choosenProduct, lightBox} = this.state;
    // console.log(
    //     "choosenProduct.product_fields",
    //     choosenProduct.product_fields
    // );

    let customIndex = lightBox.images.length;
    customIndex = customIndex + 1;

    const grid = lightBox.videos.map((video, index) => {
      const temp = customIndex;
      customIndex = customIndex + 1;
      return (
        <Col
          key={`video-${index}`}
          className="mb-4"
          md={4}
          sm={6}
          xs={6}
          onClick={() => {
            this.openLightBox(lightBox.images, lightBox.videos, temp);
          }}>
          <LazyLoadImage alt={''} effect="blur" src={defaultPlayImg} />
        </Col>
      );
    });
    return grid;
  };

  rederImageGrid = () => {
    const {choosenProduct, visibleImages} = this.state;

    let customIndex = 0;
    if (visibleImages && Object.entries(visibleImages).length > 0) {
      const grid = visibleImages.map((image, index) => {
        if (choosenProduct.product_fields[image.key]) {
          const temp = customIndex;
          customIndex = customIndex + 1;

          return this.renderImage(image.key, temp);
        }
      });

      return grid;
    } else {
      return '';
    }
  };

  renderImage = (field, index) => {
    const {choosenProduct, lightBox} = this.state;
    const proImg = choosenProduct.product_fields[field][0];

    return (
      <Col
        key={index}
        className="mb-4"
        md={4}
        sm={6}
        xs={6}
        onClick={() => {
          this.openLightBox(lightBox.images, lightBox.videos, index);
        }}>
        <LazyLoadImage alt={''} effect="blur" src={proImg} placeholder={''} />
      </Col>
    );
  };

  showData = () => {
    this.setState({
      viewPrefer: {
        ...this.state.viewPrefer,
        product: true,
      },
    });
  };

  openLightBox = (images, videos, index) => {
    const body = document.body;
    body.classList.add('hidden');

    this.setState({
      lightBox: {
        ...this.state.lightBox,
        show: true,
        index,
      },
    });
  };

  closeLightBox = () => {
    const body = document.body;
    body.classList.remove('hidden');
    this.setState({
      lightBox: {
        ...this.state.lightBox,
        show: false,
      },
    });
  };

  resetFilter = () => {
    this.setState({
      choosenProduct: {},
      fiterOption: {
        category: true,
        familie: false,
        products: false,
      },
      viewPrefer: {
        product: false,
        show_pdf: false,
        show_bilders: false,
        show_videos: false,
      },
      filterData: {
        product: '',
        category: '',
        familie: '',
      },
      products: [],
      copied: false,
    });
  };

  downloadAllPdf = () => {
    const {choosenProduct, currentLang} = this.state;

    if (
      choosenProduct.product_fields.bedienungsanleitung &&
      choosenProduct.product_fields.bedienungsanleitung != ''
    ) {
      const fileName = home_lable.operation_manual_file[currentLang];
      this.downloadFile(
        choosenProduct.product_fields.bedienungsanleitung[0],
        fileName,
      );
    }

    if (currentLang == 'en') {
      if (
        choosenProduct.product_fields.pos_karte_en &&
        choosenProduct.product_fields.pos_karte_en != ''
      ) {
        const fileName = home_lable.post_card_file[currentLang];
        this.downloadFile(
          choosenProduct.product_fields.pos_karte_en[0],
          fileName,
        );
      }
    } else {
      if (
        choosenProduct.product_fields.pos_karte_de &&
        choosenProduct.product_fields.pos_karte_de != ''
      ) {
        const fileName = home_lable.post_card_file[currentLang];
        this.downloadFile(
          choosenProduct.product_fields.pos_karte_de[0],
          fileName,
        );
      }
    }

    const generateBtn = document.getElementById('generate-pdf');
    generateBtn.click();
  };

  downloadMedia = () => {
    const {lightBox, viewPrefer} = this.state;
    if (lightBox.images && viewPrefer.show_bilders) {
      lightBox.images.map((image, index) => {
        const imageName = image.split('/');
        const name = imageName[imageName.length - 1];

        this.downloadFile(image, name);
      });
    }
    if (lightBox.videos && viewPrefer.show_videos) {
      lightBox.videos.map((video, index) => {
        const imageName = video.split('/');
        const name = imageName[imageName.length - 1];

        this.downloadFile(video, name);
      });
    }
  };

  downloadFile = (url, filename = '') => {
    if (filename == '') {
      filename = url.split('/');
      filename = filename[filename.length - 1];
    }

    this.setState({
      preparingDownload: true,
    });

    fetch(url)
      .then(async res => {
        const imgblob = await res.blob();

        if (imgblob) {
          var a = document.createElement('a');
          var url = window.URL.createObjectURL(imgblob);
          a.href = url;
          a.download = filename;
          document.body.append(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
          this.setState({
            preparingDownload: false,
          });
        }
      })
      .catch(err => {
        // console.log("Err :", err);
      });
  };

  downloadImage = (url, filename = '') =>
    this.downloadFile(`${serverUrl}/get-image-data/?url=${url}`, filename);

  downloadPdf = id => {
    api.home.downloadPdf(id);
  };

  setSearchProducts = products => {
    // this.setState({
    //     products,
    // });
  };

  render() {
    const {
      currentLang,
      copied,
      product_loading,
      families_loading,
      floatingAlert,
      fiterOption,
      categories,
      filterData,
      viewPrefer,
      families,
      products,
      choosenProduct,
      lightBox,
      preparingDownload,
      resetSearchForm,
    } = this.state;

    let copyWebUrl = `${base_url}/?product=${choosenProduct.id}`;

    copyWebUrl += filterData.category ? `&category=${filterData.category}` : '';
    copyWebUrl += filterData.familie ? `&familie=${filterData.familie}` : '';
    copyWebUrl += viewPrefer.show_pdf ? `&pdf=show` : '';
    copyWebUrl += viewPrefer.show_bilders ? `&images=show` : '';
    copyWebUrl += viewPrefer.show_videos ? `&videos=show` : '';
    copyWebUrl += viewPrefer.show_certificates ? `&certificates=show` : '';
    copyWebUrl += `&lang=${currentLang}`;

    return (
      <React.Fragment>
        <SideBarMenu toggleModal={this.toggleModal} />
        <div id="content-wrapper" className="content-wrapper front open">
          <div className="fiter-wrapper">
            <div className="site-heading">
              <h1>{home_lable.heading[currentLang]}</h1>
            </div>
            <SearchBox
              selectProduct={id => {
                this.resetFilter();
                this.setChoosenProduct(id);
              }}
              reset={resetSearchForm}
              currentLang={currentLang}
              setProducts={products => this.setSearchProducts(products)}
              updateReset={status => this.setState({resetSearchForm: status})}
            />
            <Row>
              <Col>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>
                    {home_lable.select_category_label[currentLang]}
                  </Form.Label>
                  <Form.Control
                    name="category"
                    as="select"
                    onChange={e => this.filterUpdate(e)}
                    value={filterData.category}>
                    <option value="">
                      {home_lable.select_category[currentLang]}
                    </option>
                    {Object.entries(categories).length !== 0 &&
                      categories.map((category, index) => (
                        <option key={index} value={category.category_id}>
                          {category.category_name_translations[
                            `category_name_${currentLang}`
                          ]
                            ? category.category_name_translations[
                                `category_name_${currentLang}`
                              ]
                            : category.category_reference}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            {fiterOption.familie && (
              <Row>
                <Col>
                  <Form.Group controlId="exampleForm.ControlSelect2">
                    <Form.Label>
                      {home_lable.select_family_label[currentLang]}
                    </Form.Label>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}>
                      <Form.Control
                        name="familie"
                        as="select"
                        onChange={e => {
                          this.filterUpdate(e);
                        }}
                        value={`${filterData.familie}`}>
                        <option>{home_lable.select_family[currentLang]}</option>
                        {Object.entries(families).length !== 0 &&
                          families.map((familie, index) => {
                            const familie_name = familie.translations[
                              currentLang
                            ]
                              ? familie.translations[currentLang]
                              : familie.familie_name;
                            return (
                              <option key={index} value={familie.id}>
                                {familie_name}
                              </option>
                            );
                          })}
                      </Form.Control>
                      {families_loading && (
                        <span>
                          <i className="fas fa-spinner fa-pulse loadingIcon" />
                        </span>
                      )}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            )}

            {fiterOption.products && (
              <Row>
                <Col>
                  <Form.Group controlId="exampleForm.ControlSelect3">
                    <Form.Label>
                      {home_lable.select_product_label[currentLang]}
                    </Form.Label>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}>
                      <Form.Control
                        name="product"
                        as="select"
                        onChange={e => {
                          this.filterUpdate(e);
                          this.setChoosenProduct(e.target.value);
                        }}
                        value={`${filterData.product}`}>
                        <option>
                          {home_lable.select_product[currentLang]}
                        </option>
                        {products &&
                          Object.entries(products).length !== 0 &&
                          products.map((product, index) => {
                            const product_name =
                              product.product_fields &&
                              product.product_fields[
                                `product_name_${currentLang}`
                              ] != ''
                                ? product.product_fields[
                                    `product_name_${currentLang}`
                                  ]
                                : product.product_name;
                            return (
                              <option key={index} value={index}>
                                {product_name}
                              </option>
                            );
                          })}
                      </Form.Control>
                      {product_loading && (
                        <span>
                          <i className="fas fa-spinner fa-pulse loadingIcon" />
                        </span>
                      )}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            )}

            {choosenProduct && choosenProduct.id && (
              <>
                <div className="filter-options">
                  <Form.Check
                    id="show_pdf"
                    name="show_pdf"
                    type="switch"
                    value="pdf"
                    label={home_lable.chk_pdf[currentLang]}
                    checked={viewPrefer.show_pdf ? 'checked' : ''}
                    onClick={e => {
                      this.viewPreferences(e);
                    }}
                    onChange={e => {}}
                  />

                  <Form.Check
                    id="show_bilders"
                    name="show_bilders"
                    type="switch"
                    label={home_lable.chk_images[currentLang]}
                    checked={viewPrefer.show_bilders ? 'checked' : ''}
                    onClick={e => {
                      this.viewPreferences(e);
                    }}
                    onChange={e => {}}
                  />

                  {lightBox.videos.length > 0 && (
                    <Form.Check
                      id="show_videos"
                      name="show_videos"
                      type="switch"
                      label={home_lable.chk_videos[currentLang]}
                      checked={viewPrefer.show_videos ? 'checked' : ''}
                      onClick={e => {
                        this.viewPreferences(e);
                      }}
                      onChange={e => {}}
                    />
                  )}

                  {choosenProduct &&
                    choosenProduct.product_fields.logos_design_awards &&
                    (Object.entries(
                      choosenProduct.product_fields.logos_design_awards,
                    ).length > 0 ||
                      Object.entries(
                        choosenProduct.product_fields.logos_testberichte,
                      ).length > 0 ||
                      Object.entries(
                        choosenProduct.product_fields.zertifikat_testberichte,
                      ).length > 0 ||
                      Object.entries(choosenProduct.product_fields.award_1)
                        .length > 0) && (
                      <Form.Check
                        id="show_certificates"
                        name="show_certificates"
                        type="switch"
                        label={home_lable.show_certificates[currentLang]}
                        checked={viewPrefer.show_certificates ? 'checked' : ''}
                        onClick={e => {
                          this.viewPreferences(e);
                        }}
                        onChange={e => {}}
                      />
                    )}
                </div>
                <Button onClick={() => this.showData()}>
                  <span>{home_lable.apply_btn[currentLang]}</span>
                </Button>
                <br />
                <span className="reset-text" onClick={() => this.resetFilter()}>
                  {home_lable.reset_btn[currentLang]}
                </span>

                <div className="filter-web-url">
                  <span>{copyWebUrl}</span>
                  <Button
                    onClick={() => {
                      this.setState({copied: true});
                      copy_url(copyWebUrl);
                    }}
                    className={copied ? 'active' : ''}>
                    <span className="asLink">
                      {!copied && home_lable.copy_text[currentLang]}
                      {copied && home_lable.copied_text[currentLang]}
                    </span>
                  </Button>
                </div>
              </>
            )}
          </div>

          {viewPrefer.product && (
            <div className="results-viewer">
              <Row>
                <Col md={6} xs={12}>
                  {viewPrefer.show_pdf && (
                    <>
                      <div className="pdf-lists">
                        <h3>{home_lable.chk_pdf[currentLang]}</h3>
                        <ul>
                          {choosenProduct.increpted_id &&
                            choosenProduct.increpted_id != '' && (
                              <li>
                                <i className="far fa-file-pdf" />{' '}
                                <a
                                  id="generate-pdf"
                                  className="asLink"
                                  onClick={() => {
                                    const product_name =
                                      choosenProduct.product_fields &&
                                      choosenProduct.product_fields[
                                        `product_name_${currentLang}`
                                      ] != ''
                                        ? choosenProduct.product_fields[
                                            `product_name_${currentLang}`
                                          ]
                                        : choosenProduct.product_name;

                                    const url = `${serverUrl}/products/generate-pdf/${choosenProduct.increpted_id}?language=${currentLang}`;
                                    const name = `${gen_uri(product_name)}.pdf`;
                                    this.downloadFile(url, name);
                                  }}>
                                  {home_lable.download_pdf_details[currentLang]}
                                </a>
                              </li>
                            )}

                          {choosenProduct.product_fields &&
                            choosenProduct.product_fields
                              .bedienungsanleitung && (
                              <li className="asLink">
                                <i className="far fa-file-pdf" />{' '}
                                <a
                                  id="opernational_manual"
                                  className="asLink"
                                  onClick={() => {
                                    const url =
                                      choosenProduct.product_fields
                                        .bedienungsanleitung[0];

                                    const name =
                                      home_lable.operation_manual_file[
                                        currentLang
                                      ];
                                    this.downloadFile(url, name);
                                  }}>
                                  {home_lable.download_pdf_manual[currentLang]}
                                </a>
                              </li>
                            )}

                          {choosenProduct.product_fields &&
                            choosenProduct.product_fields[
                              `pos_karte_${currentLang}`
                            ] && (
                              <li>
                                <i className="far fa-file-pdf" />{' '}
                                <a
                                  id="pos_karte_en"
                                  className="asLink"
                                  onClick={() => {
                                    const url = choosenProduct.product_fields[
                                      `pos_karte_${currentLang}`
                                    ]
                                      ? choosenProduct.product_fields[
                                          `pos_karte_${currentLang}`
                                        ][0]
                                      : choosenProduct.product_fields
                                          .pos_karte_de[0];

                                    const name =
                                      home_lable.post_card_file[currentLang];
                                    this.downloadFile(url, name);
                                  }}>
                                  {
                                    home_lable.download_pdf_pos_karte[
                                      currentLang
                                    ]
                                  }
                                </a>
                              </li>
                            )}

                          {choosenProduct.product_fields &&
                            choosenProduct.product_fields[
                              `zuesaetzliche_dokumente_${currentLang}`
                            ] && (
                              <li>
                                <i className="far fa-file-pdf" />{' '}
                                <a
                                  id="zuesaetzliche_dokumente_en"
                                  className="asLink"
                                  onClick={() => {
                                    const url = choosenProduct.product_fields[
                                      `zuesaetzliche_dokumente_${currentLang}`
                                    ]
                                      ? choosenProduct.product_fields[
                                          `zuesaetzliche_dokumente_${currentLang}`
                                        ][0]
                                      : choosenProduct.product_fields
                                          .zuesaetzliche_dokumente_de[0];

                                    const name =
                                      home_lable.zuesaetzliche_dokumente_file[
                                        currentLang
                                      ];
                                    this.downloadFile(url, name);
                                  }}>
                                  {
                                    home_lable
                                      .download_pdf_zuesaetzliche_dokumente[
                                      currentLang
                                    ]
                                  }
                                </a>
                              </li>
                            )}

                          {Object.entries(choosenProduct).length != 0 && (
                            <li
                              className="asLink"
                              onClick={() => {
                                this.downloadAllPdf();
                              }}>
                              <i className="far fa-file-pdf" />{' '}
                              {home_lable.download_all_pdf[currentLang]}
                            </li>
                          )}
                        </ul>
                      </div>
                    </>
                  )}
                  {viewPrefer.show_pdf && viewPrefer.show_certificates && (
                    <span className="divider-line" />
                  )}
                  {viewPrefer.show_certificates && (
                    <ListCetificates
                      currentLang={currentLang}
                      data={choosenProduct}
                      downloadFile={(url, filename) =>
                        this.downloadFile(url, filename)
                      }
                    />
                  )}
                </Col>
                <Col md={6} xs={12}>
                  <div className="image-grids">
                    {(viewPrefer.show_bilders || viewPrefer.show_videos) && (
                      <h3>{`${home_lable.chk_images[currentLang]} / ${home_lable.chk_videos[currentLang]}`}</h3>
                    )}
                    <Row>
                      {viewPrefer.show_bilders && this.rederImageGrid()}
                      {viewPrefer.show_videos && this.rederVideoGrid()}
                    </Row>
                    {((lightBox.images.length > 0 && viewPrefer.show_bilders) ||
                      (lightBox.videos.length > 0 &&
                        viewPrefer.show_videos)) && (
                      <span className="download-links">
                        <a
                          onClick={() => {
                            const url = `${serverUrl}/export-all-media/${choosenProduct.increpted_id}`;
                            const tempName = gen_uri(
                              choosenProduct.product_name,
                            );
                            // console.log("url", url,tempName);
                            const name = `${tempName}.zip`;
                            this.downloadFile(url, name);
                          }}

                          // href={`${serverUrl}/export-all-media/${choosenProduct.increpted_id}`}
                          // target="blank"
                        >
                          {home_lable.download_all_images[currentLang]}
                        </a>
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </div>

        {lightBox.show && (
          <LightBox
            images={lightBox.images}
            videos={lightBox.videos}
            currentIndex={lightBox.index}
            closeLightBox={this.closeLightBox}
            downloadFile={(url, name) => {
              this.downloadImage(url, name);
            }}
          />
        )}

        {floatingAlert.show && (
          <FloatingAlert
            show={floatingAlert.show}
            variant={floatingAlert.variant}
            message={floatingAlert.message}
            currentLang={currentLang}
            closeAlert={this.closeAlert}
          />
        )}

        {preparingDownload && (
          <PreparingDownload
            currentLang={currentLang}
            show={preparingDownload}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateProrp = state => {
  return {
    isAuthenticated: !!state.user.accessToken,
    filters: state.filtersList,
    famillies: state.filterFamillies,
    products: state.productsList,
    currentuser: state.user,
    currentLang: state.languagePref,
  };
};

HomePage.propTypes = {};

export default connect(mapStateProrp, {
  getFilterOptions,
  get_famillies,
  getHomeProducts,
  homeGetImageStatus,
  getProductWithId,
})(HomePage);
